import { SelectionChangedEvent } from "ag-grid-community";
import { BillingCycleEntity } from "domain/entity/BillingCycle/BillingCycleEntity";
import _ from "lodash";
import { INITIAL_BILLING_CYCLE_COL_DEF } from "presentation/constant/BillingCycle/BillingCycleMaintenanceColumnDefinition";
import { BillingCycleMaintenanceConstant } from "presentation/constant/BillingCycle/BillingCycleMaintenanceConstant";
import { useBillingCycleMaintenanceVM } from "presentation/hook/BillingCycle/useBillingCycleMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef } from "react";


const BillingCycleTablePanel: React.FC = () => {
    const [billingCycleState] = useBillingCycleMaintenanceTracked();
    const billingCycleVM = useBillingCycleMaintenanceVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        billingCycleVM.updateSelectedRows(selectedRows);
    }, [billingCycleVM])

    const handleAddClick = useCallback(() => {
        billingCycleVM.onAdd();
    }, [billingCycleVM])

    const handleRowDoubleClick = useCallback((entity: BillingCycleEntity) => {
        billingCycleVM.onEdit(entity);
    }, [billingCycleVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(billingCycleState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, billingCycleState.selectedRows])

    const memoTariffCodeTable = useMemo(() => {
        return (
            <NbisTable
                id='tariff-code-table'
                headerLabel={BillingCycleMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_BILLING_CYCLE_COL_DEF}
                data={billingCycleState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: BillingCycleEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [billingCycleState.tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTariffCodeTable}
    </TableWrapper>
    </>;
}

export default memo(BillingCycleTablePanel);
